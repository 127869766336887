import { TaskActivity } from "./TaskActivityRenderer/types";
import { TaskActivityRenderer } from "./TaskActivityRenderer";

import { SmartTextInput } from "components/SmartTextInput";
import { compareAsc, format } from "date-fns";
import { useCurrentProject } from "hooks/useCurrentProject";
import {
  Task,
  useCreateCommentMutation,
  useMeQuery,
} from "modules/api/generated-api";
import { useMemo, useState } from "react";
import { Descendant } from "slate";
import { twMerge } from "tailwind-merge";

export function TaskActivityComponent({ task }: { task: Task }) {
  const { data: userData } = useMeQuery();
  const { project } = useCurrentProject();
  const [createComment] = useCreateCommentMutation();
  const [editorData, setEditorData] = useState<Descendant[]>();
  const taskActivity = useMemo(() => {
    return [
      ...(task.comments?.map((c) => ({
        ...c,
        reportType: "comment",
      })) as TaskActivity[]),
      ...(task.dailyProgress?.map((c) => ({
        ...c,
        reportType: "progress-report",
      })) as TaskActivity[]),
      ...(task.uploads?.map((c) => ({
        ...c,
        reportType: "upload",
      })) as TaskActivity[]),
    ].sort((a, b) => compareAsc(b.ts, a.ts));
  }, [task.comments, task.dailyProgress, task.uploads]);

  const currentUser = userData?.me;
  const hasAttachments = task.uploads && task.uploads.length > 0;
  if (!currentUser || !project) return <div>Loading...</div>;

  return (
    <>
      {hasAttachments && (
        <>
          <div className="text-sm font-bold p-2 mx-4 mt-4 border-t border-ink text-center">
            Attachments
          </div>
          <div className="flex flex-col px-8 gap-2">
            {task.uploads?.map((upload) => (
              <a href={upload.key} className="">
                <button className="flex size-full hover:bg-gray-dim rounded text-sm items-center gap-4 px-2">
                  <div className="size-10 rounded-xl bg-white flex justify-center items-center text-center text-lg">
                    {upload.name
                      .slice(upload.name.lastIndexOf("."))
                      .substring(1)}
                  </div>
                  <div className="text-left">
                    <div>{upload.name}</div>
                    <div>
                      Uploaded on {format(upload.ts, "d MMM yyyy, hh:mm aa")}
                    </div>
                    <div>by {upload.user.name}</div>
                  </div>
                </button>
              </a>
            ))}
          </div>
        </>
      )}
      <div></div>
      <div
        className={twMerge(
          "text-sm font-bold p-2 mx-4 mt-4 border-ink text-center",
          !hasAttachments && "border-t",
        )}
      >
        Comments
      </div>
      <div className="flex flex-col px-8 text-xs gap-2">
        <div className="flex flex-row items-center gap-2">
          <div className="flex-1">
            <SmartTextInput
              editorData={editorData}
              onChange={(v) => {
                setEditorData(v);
              }}
              clearOnSubmit
              callbacks={{
                onSubmit: async (content) => {
                  await createComment({
                    input: {
                      content,
                      task: task._id,
                    },
                  });
                  setEditorData([
                    { children: [{ text: "" }], type: "paragraph" },
                  ]);
                },
              }}
              placeholder={"Enter your comment here..."}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {taskActivity.map((taskActivityElement, index) => {
            return (
              <div
                key={`daily-progress-${index}`}
                className="flex flex-row items-center break-auto hyphens-auto whitespace-break-spaces group gap-2"
              >
                <TaskActivityRenderer taskActivity={taskActivityElement} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
