import { useAppSelector } from "@redux/hooks";

import { TaskActivityComponent } from "./TaskActivity/TaskActivity";
import { TaskDetails } from "./TaskDetails";

import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { SmartTextInput } from "components/SmartTextInput";
import { useCurrentProject } from "hooks/useCurrentProject";
import { useEscapeBack } from "hooks/useEscapeBack";
import {
  Task,
  useGetOneTaskQuery,
  useUpdateTaskMutation,
} from "modules/api/generated-api";
import { useUploadMutation } from "modules/api/rest-api";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { PROJECT_PATH, TASK_PATH } from "routes/paths";
import { Descendant } from "slate";
import { useDebounceCallback } from "usehooks-ts";

export function TaskPage() {
  const { taskId } = useParams();
  const { currentUser } = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);
  const { project } = useCurrentProject();
  const { data, isLoading } = useGetOneTaskQuery({ id: taskId as string });
  const task = data?.task as Task;
  const [updateTask] = useUpdateTaskMutation();
  const [upload] = useUploadMutation();
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!task) return;
      const uploadResponse = await upload({
        file: acceptedFiles[0],
      });
      if (uploadResponse.data) {
        const uploads = task.uploads!.map((u) => u._id);
        uploads.push(uploadResponse.data._id);
        updateTask({ input: { id: task._id, uploads } });
      }
    },
    [task, updateTask, upload],
  );
  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
  });

  useEscapeBack();

  const [content, setContent] = useState(task?.content);
  const [title, setTitle] = useState(task?.title);
  const [editorData, setEditorData] = useState<Descendant[]>(task?.content);
  const [editorDataForce, setEditorDataForce] = useState<Descendant[]>();

  const onContentUpdate = useCallback((_content: any) => {
    updateTask({ input: { content: _content, id: taskId! } });
  }, []);
  const onContentUpdateDebounced = useDebounceCallback(onContentUpdate, 500);

  const onTitleUpdate = useCallback((_title: string) => {
    updateTask({ input: { id: taskId!, title: _title } });
  }, []);

  const onTitleUpdateDebounced = useDebounceCallback(onTitleUpdate, 500);
  // @TODO: add back later
  // const { addActivity } = useActivityLogger<TaskType>({ ...task });

  useEffect(() => {
    if ((task as any)?.updateByUser === currentUser) return;
    setEditorDataForce(task?.content as any);
  }, [currentUser, task, task?.content]);
  useEffect(() => {
    setTitle(task?.title);
  }, [task?.title]);

  if (!project) {
    return <div>Project not found</div>;
  }
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!task) {
    return <div>Task not found</div>;
  }

  return (
    <div className="flex flex-1">
      <div
        className="flex flex-col flex-1 relative overflow-y-scroll"
        {...getRootProps()}
      >
        {isDragActive && (
          <div className="absolute inset-0 bg-slate-500 z-10 flex items-center justify-center">
            <div className="p-10 border border-dashed border-ink rounded-2xl">
              Drop files here to upload
            </div>
          </div>
        )}
        <Breadcrumbs
          crumbs={[
            {
              name: project.name,
              path: PROJECT_PATH.replace(":projectId", project._id),
            },
            {
              name: project.code + "-" + task.counterId,
              path: TASK_PATH.replace(":projectId", projects.current!).replace(
                ":taskId",
                task._id,
              ),
            },
          ]}
          className="p-4 hidden md:block"
        />
        <div className="flex flex-col flex-1 relative">
          <div className="flex flex-col gap-4 p-8 py-4">
            <input
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                onTitleUpdateDebounced(e.target.value);
                // @TODO: add activity on the backend
                // const newTask = {
                //   ...task,
                //   title: e.target.value,
                // };
                // dispatch(projectSlice.actions.updateTask(newTask));
                // addActivity(newTask);
              }}
              className="bg-transparent text-xl outline-none placeholder-gray-400"
            />
            {
              <SmartTextInput
                showFormattingBar
                editorDataForce={editorDataForce}
                editorData={editorData}
                onChange={(v) => {
                  setEditorData(v);
                  onContentUpdateDebounced(v);
                }}
                placeholder={"Enter task description..."}
              />
            }
          </div>
          <div className="flex justify-center">
            <div className="mb-4 flex-1 xl:max-w-[800px]">
              <TaskActivityComponent task={task} />
            </div>
          </div>
        </div>
      </div>
      <TaskDetails task={task as Task} />
    </div>
  );
}
